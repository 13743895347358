import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-bootstrap';
import ContentBlock from 'src/components/functional/content-block';
import ArbiasButton from 'src/components/functional/arbias-button';
import {H3} from 'src/components/functional/headings';
import {useContent} from 'src/functionality/content-provider';

const AbiInformationSection = (props) => {
  const content = useContent('GC02');
  return (
    <section>
      <Row>
        <Col lg={12} xl={10}>
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={{[props.tag ??'GC02']: props.content ?? content}}
            tag={props.tag ?? 'GC02'}
            linkText="Information >"
            link={
              (props) => <ArbiasButton to="/information/" {...props}/>
            }
          />
        </Col>
      </Row>
    </section>
  );
};

AbiInformationSection.propTypes = {
  content: PropTypes.object,
  tag: PropTypes.string,
};


export default AbiInformationSection;
