
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {Col, Row} from 'react-bootstrap';
import styled from 'styled-components';
import ContactButton from 'src/components/functional/contact-button';
import ContentBlock from 'src/components/functional/content-block';
import {H3, H6} from 'src/components/functional/headings';
import {breakpoints} from 'src/styles/breakpoints';
import {useContent} from 'src/functionality/content-provider';
import map from 'images/map.svg';

const FindUs = styled.section``;

const FindUsCol = styled(Col)`
  padding: 0.5em;
  padding-right: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Center = styled.div`
  margin: auto;
  width: fit-content;
`;

const Map = styled.img`
  padding: 3vw;
  @media ${breakpoints.md} {
    padding: 0;
  }
`;

const FindUsSection = (props) => {
  const CT04 = useContent('CT04');
  const CT05 = useContent('CT05');
  const CT06 = useContent('CT06');
  const contact = useRef(null);
  const scrollToContact = () => contact.current.scrollIntoView();

  useEffect(() => {
    if (window.location.hash === '#contact') {
      scrollToContact();
      history.pushState('', document.title, // Remove hash from url
          window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <FindUs ref={contact}>
      <Center>
        <H3 className="text-center">Find Us</H3>
      </Center>
      <Row>
        <FindUsCol lg={6} md={4} sm={12}>
          <Map src={map} alt="Eastern Seaboard" />
        </FindUsCol>
        <FindUsCol lg={6} md={8} sm={12}>
          <ContentBlock
            header={(props) => (
              <H6 noUnderline className="text-muted" {...props}></H6>
            )}
            content={{ CT04: CT04 }}
            tag="CT04"
          />
          <ContentBlock
            header={(props) => (
              <H6 noUnderline className="text-muted" {...props}></H6>
            )}
            content={{ CT05: CT05 }}
            tag="CT05"
          />
          {/* <ContentBlock
            header={(props) =>
              <H6 noUnderline className="text-muted" {...props}></H6>
            }
            content={{'CT06': CT06}}
            tag="CT06"
          /> */}
          <ContactButton right>Contact Us</ContactButton>
        </FindUsCol>
      </Row>
    </FindUs>
  );
};

FindUsSection.propTypes = {
  content: PropTypes.shape({
    A04: PropTypes.any,
    A05: PropTypes.any,
    A06: PropTypes.any,
  }),
};


export default FindUsSection;
