import {useLocation} from '@reach/router';
import BikeImage from 'images/about/bike.png';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {usePageContent} from 'src/functionality/content-provider';
import {breakpoints} from 'src/styles/breakpoints';
import {theme} from 'src/styles/theme';
import styled from 'styled-components';
import NewsAndAnnouncements
  from 'src/components/functional/news-announcements-section';
import AbiInformationSection
  from 'src/components/functional/abi-information-section';
import ArbiasButton from 'src/components/functional/arbias-button';
import ContentBlock from 'src/components/functional/content-block';
import {H1, H3} from 'src/components/functional/headings';
import Page from 'src/components/functional/page';
import Spacer from 'src/components/graphical/spacer';
import OurPhilosophySection
  from 'src/components/functional/our-philosophy-section';
import FindUsSection from 'src/components/functional/find-us-section';
import SEO from 'src/components/seo';

import PeopleImg from 'images/about/people.jpg';
import Strip from 'src/components/functional/strip';

const Primary = styled.span`
  color: ${theme.primary};
`;

const HeroSection = styled.section`
  width: 100vw;
  min-height: 70vh;
  padding: 5vh 0;
  display: flex;
  align-items: center;
  color: ${theme.light};
  
  background-image: url(${BikeImage});
  background-position: bottom right;
  background-size: cover;
`;

const HeroLead = styled.div`
  width: 70%;
  @media ${breakpoints.md}{
    width: 90%;
    margin: auto;
  }
`;

const PeopleSection = styled.section``;

const PeopleCol = styled((props) => <Col {...props} />)`
  display: flex;
  align-items: center;
  text-align: right;
  `;

const PeopleImage = styled.img`
  max-height: 40vh;
`;

const WhatWeDoSection = styled.section``;

const Center = styled.div`
  margin: auto;
  width: fit-content;
  text-align: center;
`;

const AboutPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);

  return (
    <Page white>
      <HeroSection>
        <Container>
          <H1 display={1} light={true} noUnderline>
            <span>
              About <Primary>arbias</Primary>
            </span>
          </H1>
          <Spacer height={2}/>
          <div>
            <ContentBlock
              header={(props) => <H3 light={true} {...props}/>}
              content={content}
              tag="A01"
              contentWrapper={
                (props) => <HeroLead className="lead" {...props}/>}
            />
          </div>
        </Container>
      </HeroSection>
      <WhatWeDoSection>
        <Strip bg={theme.bodyBg} padding="4vh">
          <ContentBlock
            header={(props) => <Center><H3 {...props}/></Center>}
            content={content}
            tag="A08"
          />
          <Spacer height={2} />
          <Center>
            <ArbiasButton to="/about/what-we-do/">
              Learn More &gt;
            </ArbiasButton>
          </Center>
        </Strip>
      </WhatWeDoSection>
      <Spacer height={4}/>
      <PeopleSection>
        <Container>
          <Row>
            <PeopleCol md={12} lg={6}>
              <PeopleImage src={PeopleImg} alt="TODO:" />
            </PeopleCol>
            <PeopleCol md={12} lg={6}>
              <div>
                <Spacer height={2}/>
                <ContentBlock
                  header={(props) => <H3 {...props}/>}
                  content={content}
                  tag="A02"
                  link={
                    (props) =>
                      <ArbiasButton
                        {...props}
                        right={true}
                        to="/about/people/"
                      />
                  }
                  linkText="People &gt;"
                />
                <Spacer height={2}/>
              </div>
            </PeopleCol>
          </Row>
        </Container>
      </PeopleSection>
      <Spacer height={4}/>
      <Strip bg={theme.bodyBg} padding="4vh">
        <AbiInformationSection tag="A04" content={content?.A04}/>
      </Strip>
      <Spacer height={4}/>
      <Container>
        <FindUsSection/>
      </Container>
      <Spacer height={8}/>
      <Strip bg={theme.bodyBg} padding="4vh">
        <NewsAndAnnouncements />
      </Strip>
      <OurPhilosophySection content={content?.A07} tag="A07"/>
    </Page>
  );
};

AboutPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default AboutPage;

// export const Head = () => (
//   <SEO
//     title="About Us"
//     description="arbias is a leading provider of specialist services for people with acquired brain injury and complex needs, making a difference in the lives of our clients. From NDIS support services and neuropsychological assessments to community programs and industry training modules, arbias is committed to enabling individuals and partners to achieve their best. Learn more about our philosophy and services, and find out how we can help support people with acquired brain injury."
//   />
// );
