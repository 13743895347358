import React from 'react';
import {Link} from 'gatsby';
import usePublications from 'src/functionality/publication-provider';
import {theme} from 'src/styles/theme';

import {Container, Row, Col} from 'react-bootstrap';
import Spacer from 'src/components/graphical/spacer';
import ArbiasButton from 'src/components/functional/arbias-button';
import {H3, H5} from 'src/components/functional/headings';
import styled from 'styled-components';

const NewsSection = styled.section`
`;

const PublicationCol = styled((props) => <Col {...props} />)`
  display: flex;
  background-color: ${theme.light};
  flex-direction: column;
  justify-content: space-between;
  padding: 1em 0.5em;
  margin: 1em 0.5em;
  min-width: 15em;
`;

const PublicationLink = styled(Link)`
  text-decoration: none;
  color: ${theme.dark};
  &:hover h5{
    color: ${theme.primary};
  }
`;

const PublicationImage = styled.img`
  max-height: 13rem;
  margin-bottom: 0.5em;
`;

const NewsAndAnnouncements = (props) => {
  const publications = usePublications();
  return (
    <NewsSection>
      {publications?.length > 0 &&
        <Container>
          <H3>News and Announcements</H3>
          <Row>
            {publications.slice(0, 4)
                .map((publication, key) => {
                  return publication ? (
                    <PublicationCol key={key} className="shadow-sm" >
                      <PublicationLink
                        to={`/about/publications#${publication.slug}`}
                      >
                        <div>
                          <PublicationImage
                            src={publication.image}
                            alt={publication.title}
                          />
                          <H5 noUnderline>{publication.title}</H5>
                          <p>
                            {publication.excerpt}
                          </p>
                        </div>
                      </PublicationLink>
                    </PublicationCol>
                  ) : <></>;
                })}
          </Row>
          <Spacer height={2}/>
          <ArbiasButton to="/about/publications/">
            All Publications
          </ArbiasButton>
          <Spacer height={2}/>
        </Container>
      }
    </NewsSection>
  );
};


export default NewsAndAnnouncements;
