import React from 'react';
import PropTypes from 'prop-types';
import OurPhilosophyImage from 'images/about/alley.jpg';

import {Container} from 'react-bootstrap';
import {H3} from 'src/components/functional/headings';
import styled from 'styled-components';
import ContentBlock from 'src/components/functional/content-block';
import ArbiasButton from 'src/components/functional/arbias-button';
import {breakpoints} from 'src/styles/breakpoints';
import {useContent} from 'src/functionality/content-provider';

const Lead = styled.div`
  width: 70%;
  @media ${breakpoints.md}{
    width: 90%;
    margin: auto;
  }
`;
const OurPhilosophy = styled.section`
  padding: 10vh 1em;
  
  background-image: 
    linear-gradient(
      rgba(83, 83, 83, 0.4), 
      rgba(83, 83, 83, 0.4)
    ),
    url(${OurPhilosophyImage});
  background-size: cover;
  background-position: center center;
  
`;

const OurPhilosophySection = (props) => {
  const content = useContent('A07');
  return (
    <OurPhilosophy>
      <Container>
        <ContentBlock
          header={(props) => <H3 light={true} {...props}/>}
          content={props.content ?
              {[props.tag]: props.content} : {'A07': content}}
          tag={props.tag ?? 'A07'}
          contentWrapper={(props) =>
            <Lead className="lead text-light" {...props}/>
          }
          link={(props) =>
            <ArbiasButton dark={true} {...props}
              href="/about/our-philosophy/"/>
          }
          linkText="Learn More"
        />
      </Container>
    </OurPhilosophy>
  );
};

OurPhilosophySection.propTypes = {
  content: PropTypes.object,
  tag: PropTypes.string,
};

export default OurPhilosophySection;
